import { Outlet } from 'react-router-dom';
import ThemeProvider from '@/context/ThemeContext';
import AuthProvider from '@/context/AuthContext';
import { Toaster } from '@/components/ui/sonner';
import NotificationProvider from '@/context/NotificationContext';
import { useMixpanelTracking } from '@/lib/hooks/use-mixpanel-tracking';
import BillingProvider from '@/context/BillingContext';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import type { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';

export function Providers() {
  useMixpanelTracking();

  const gtmParams: ISnippetsParams | undefined =
    import.meta.env.MODE === 'development'
      ? undefined
      : {
          id: import.meta.env.VITE_GTM_ID,
        };

  return (
    <GTMProvider state={gtmParams}>
      <NotificationProvider>
        <AuthProvider>
          <BillingProvider>
            <ThemeProvider>
              <Outlet />
              <Toaster />
            </ThemeProvider>
          </BillingProvider>
        </AuthProvider>
      </NotificationProvider>
    </GTMProvider>
  );
}

export { Providers as Component };
